import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageNewsletter from '../components/pages/newsletter'

export default function PageGatsbyNewsletter(props: PageProps) {
  const {
    title,
    description
  } = (props.data as any).content.edges[0].node.childPagesJson

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroContent={<PageNewsletter />}
      heroCenter
    >
      &nbsp;
    </Layout>
  )
}

export const contentQuery = graphql`
  query NewsletterPageQuery {
    content: allFile(
      filter: { relativePath: { eq: "pages/newsletter.json" } }
    ) {
      edges {
        node {
          childPagesJson {
            title
            description
          }
        }
      }
    }
  }
`
