import React from 'react'
import FormNewsletter from './FormNewsletter'
import styles from './index.module.css'

export default function PageNewsletter() {
  return (
    <div className={styles.content}>
      <FormNewsletter />
    </div>
  )
}
